/* Asegúrate de aplicar estilos al contenedor completo */
.privacy-policy-page {
  background-color: #0e0e0e; /* Fondo negro */
  color: #ffffff; /* Texto blanco para contraste */
  min-height: 100vh; /* Ocupa todo el viewport */
  display: flex;
  flex-direction: column;
}

.privacy-policy-content {
    max-width: 900px;
    margin: 0 auto;
    padding: 5px 0px 0px 0px;
    background-color: #0e0e0e;
    color: #ffffff;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .privacy-policy-content h1,
  .privacy-policy-content h2,
  .privacy-policy-content h3,
  .privacy-policy-content h4 {
    color: #ffffff;
    margin-top: 0px;
  }
  
  .privacy-policy-content p {
    margin-bottom: 15px;
  }
  
  .privacy-policy-content li {
    margin-bottom: 10px;
  }
  
  .privacy-policy-content a {
    color: #0066cc;
    text-decoration: none;
  }
  
  .privacy-policy-content a:hover {
    text-decoration: underline;
  }