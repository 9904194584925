.homepage-footer {
    background-color: #0e0e0e;
    color: #fff;
    padding: 20px 20px 0px 20px;
    margin-top: auto; /* Importante para el diseño sticky */
    border-top: 1px solid #262626;
}

.footer-content {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-section {
    flex: 1;
    min-width: 200px;
    margin: 10px;
}

.footer-section h4 {
    font-size: 13px;
    margin-bottom: 10px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section li {
    margin-bottom: 8px;
}

.footer-section a {
    font-size: 13px;
    color: #b8b8b8;
    text-decoration: none;
}

.footer-section a:hover {
    color: #fff;
}

.footer-bottom {
    text-align: center;
    padding: 10px;
    font-size: 12px;
    border-top: 1px solid #262626;
    margin-top: 20px;
}

.footer-section p {
    font-size: 13px;
    color: #b8b8b8;
    margin-bottom: 5px;
}

/* Estilos para el ícono de LinkedIn */
.linkedin-icon {
    font-size: 24px;
    color: #0e76a8;
    text-decoration: none;
}

.linkedin-icon:hover {
    color: #005582;
}
