.text-tutorial h2 {
    max-width: 800px;        /* Limita el ancho máximo a 800px */
    margin: 0 auto;          /* Centra el bloque horizontalmente */
    text-align: left;        /* Alinea el contenido dentro del bloque a la izquierda */

    font-size: 20px !important; 
    color: #f6f6f6;
    padding: 0px 0px 10px 0px; 
}

.body-container {
    width: 100%;
    background-color: transparent; /* Fondo específico del grupo */
    padding: 20px;
    margin: 0 auto; /* Centrado horizontal */
}

/* Estilos para el grupo PromptsEg */
.prompts-eg-container {
    margin: 0 auto;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%; /* Opcional: hace que los botones ocupen todo el ancho del contenedor */
}

/* Estilos para botones */
.prompt-button {
    width: 100%;
    color: #262626;
    background-color: #e7e7e7;
    border: none;
    padding: 5px 7px;
    font-size: 16px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.prompt-button:hover {
    background-color: #b0b0b0;
}


.spinner-container {
    display: flex;
    justify-content: center;  /* centrar horizontalmente */
    align-items: center;      /* centrar verticalmente */
    margin-top: 20px;
    background-color: #0e0e0e;
    color: #d1d1d1;
  }
  
  /* Clase para el spinner de Bootstrap, pero con estilos propios */
  .spinner-container {
    width: 32px;              /* tamaño del spinner */
    height: 32px;
    color: #f6f6f6;           /* color del spinner */
    border-width: 3px;        /* grosor del borde */
    margin: 0 auto;
  }
