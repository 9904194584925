.about-page {
    background-color: #0e0e0e; /* Fondo negro */
  }

.about-content {
    background-color: #0e0e0e;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    color: #cacaca;
    font-size: 16px;
    line-height: 1.6;
}
  
.about-section {
    margin-top: 50px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

.about-section.visible {
    opacity: 1;
    transform: translateY(0);
}

.about-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.about-section p {
    margin-bottom: 20px;
}

.team-photos {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.team-member {
    text-align: center;
}

.team-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.team-name {
    margin-bottom: 5px !important;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #cacaca;
}

.team-role {
    font-size: 14px;
    color: #b0b0b0;
}
  