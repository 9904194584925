.terms-of-use-page {
  background-color: #0e0e0e; /* Fondo negro */
  color: #ffffff; /* Texto blanco */
  min-height: 100vh; /* Asegura que ocupa todo el viewport */
  display: flex;
  flex-direction: column;
}

.terms-of-use-content {
    max-width: 900px;
    margin: 0 auto;
    padding: 0px 0px 0px 0px;
    background-color: #0e0e0e;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .terms-of-use-content h1,
  .terms-of-use-content h2,
  .terms-of-use-content h3,
  .terms-of-use-content h4 {
    color: #ffffff;
    margin-top: 0px;
  }
  
  .terms-of-use-content p {
    margin-bottom: 15px;
  }
  
  .terms-of-use-content ol,
  .terms-of-use-content ul {
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  .terms-of-use-content li {
    margin-bottom: 10px;
  }
  
  .terms-of-use-content a {
    color: #0066cc;
    text-decoration: none;
  }
  
  .terms-of-use-content a:hover {
    text-decoration: underline;
  }
  