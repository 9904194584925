/* Contenedor Principal */
.displayed-products-container h2 {
    max-width: 800px;  /* Limita el ancho máximo a 800px */
    margin: 0 auto;    /* Centra el elemento horizontalmente */
    text-align: left;  /* Alinea el texto a la izquierda dentro de esos 800px */
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
    white-space: pre-wrap;
}

/* Lista de Productos */
.product-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; /* Configura flexbox */
    flex-wrap: wrap; /* Permite que los elementos se envuelvan si el espacio es insuficiente */
    gap: 20px; /* Espaciado entre los elementos */
    justify-content: center; /* Centra los productos horizontalmente */
}

/* Título del Producto */
.product-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #f6f6f6;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;

    /* ----- PROPIEDADES PARA MULTILINE ELLIPSIS ----- */
    display: -webkit-box;           /* Para permitir -webkit-line-clamp */
    -webkit-line-clamp: 3;          /* Limita a 3 líneas */
    line-clamp: 3;                  /* Versión estándar de line-clamp */
    -webkit-box-orient: vertical;   /* Orienta el contenedor en vertical */
    overflow: hidden;               /* Oculta el texto que sobrepasa */
    text-overflow: ellipsis;        /* Muestra "..." al final del texto */
    line-height: 1.4rem;            /* Ajusta la altura de línea si quieres */
    max-height: calc(1.4rem * 3);   /* Opcional, para mayor control */
}

.product-details p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #ffffff;
}

.product-details strong {
    color: #d1d1d1;
}

.product-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* opcional */
    justify-content: flex-start; /* opcional */
}

/* Imagen del Producto */
.product-image img {
    display: block;           /* Para poder centrar con margin: 0 auto */
    margin: 0 auto;           /* Centra horizontalmente la imagen */
    max-width: 200px;         /* Ajusta este valor según el tamaño deseado */
    height: auto;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Detalles del Producto */
.product-details {
    flex: 1;
    margin-left: 20px;
}

.product-item {
    background-color: #0e0e0e;
    border-radius: 5px;
    padding: 15px;
    width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #3d3d3d; /* Línea de 1px, color gris */
    display: flex;
    flex-direction: column;
    transition: transform 0.2s; /* Añade una animación al hover */
}

.product-item:hover {
    transform: scale(1.02); /* Amplía ligeramente el producto */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-item[style*="cursor: pointer"]:hover {
    background-color: #1e1e1e; /* Cambia el color de fondo si es clicable */
}

.product-item[style*="cursor: default"]:hover {
    cursor: pointer;
}

.displayed-products-container h2.screen-message {
    font-size: 20px;
    color: #b0b0b0;
    text-align: center;
}

\/* Contenedor de precio */
.price-container {
    margin: 10px 0;
}

/* Estilo para solo el valor del precio */
.product-price {
    font-size: 1.5rem;  /* Tamaño del texto del precio */
    font-weight: bold;  /* Negrita */
    color: #f6f6f6;     /* Ejemplo: color dorado */
    display: inline;    /* Asegura que se vea inline */
}
