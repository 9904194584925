.custom-header {
  width: 100%;
  background-color: #262626;
  display: flex;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.header-left-container {
  padding:0px;
  width: 100%;
  height: 54px;
  background-color: #262626;
  display: flex;
  justify-content: center;
}

/* Estilos para el header interno */
.header-left-inner {
  display: flex;
  align-items: flex-start; /* Alinea todo el contenido en la parte superior */
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  padding: 0px; /* Ajusta el espacio interno */
  box-sizing: border-box;
  gap: 20px; /* Añade un espacio entre los elementos */
}

/* Estilos para el contenido de la izquierda */
.header-left-content {
  display: flex;
  align-items: center; /* Centra verticalmente solo el contenido de header-left */
}

/* Estilos para el título */
.header-left-title {
  font-size: 23px;
  font-weight: 400;
  color: #ffffff;
}

/* Estilos para el logo */
.header-left-logo {
  padding: 0px;
  width: 31px;
  height: auto;
  margin: 6px;
  color: #b8b8b8; /* Establece aquí el color deseado */
}

/* Si tienes una barra de chat u otro elemento */
.header-left-chatbar {
  position: relative; /* Necesario para que el ChatBar use absolute relativo al Header */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
}
