.search-engine-reference {
  display: flex;
  height: 91vh;
  font-family: Arial, sans-serif;
  color: #f6f6f6;
}

/* Sidebar */
.sidebar {
  width: 400px;
  background-color: #0e0e0e;
  padding: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.sidebar h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #4028ca;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #e7e7e7;
  font-weight: bold;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}

/* Nested Sidebar Items */
.sidebar ul ul {
  margin-left: 0px; /* Indentation for child items */
  padding-left: 5px;
  border-left: 2px solid #ddd; /* Visual distinction for nested items */
}

.sidebar ul ul li a {
  color: #e7e7e7; /* Slightly darker shade for child links */
  font-weight: normal; /* De-emphasized compared to parent links */
  font-size: 0.9rem; /* Slightly smaller font size for nested items */
}

/* Hide sidebar completely when collapsed */
.sidebar.collapsed {
  transform: translateX(-100%);
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.header-search-engine {
  display: flex;
}

/* Toggle button repositioned in documentation area */
.collapse-toggle {
  background: #0e0e0e;
  color: #e7e7e7;
  border: none;
  cursor: pointer;
  padding: 0px 10px;
  margin: 0px 10px;
  border-radius: 4px;
  top: 10px;/* Ensure it stays above other elements */
}

.collapse-toggle:hover {
  color: #6d6d6d;
}

/* Documentation Content */
.documentation {
  flex-grow: 1;
  padding: 0px 20px;
  overflow-y: auto;
  background-color: #262626;
  position: relative;
}

.documentation h2 {
  color: #e7e7e7;
  border-bottom: 2px solid #3d3d3d;
  padding-bottom: 5px;
  margin-top: 0px;
}

.documentation h3 {
  color: #d1d1d1;
  margin-top: 20px;
}

.documentation p {
  line-height: 1.6;
  margin-bottom: 20px;
}

.documentation ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}

.documentation ul li {
  margin-bottom: 10px;
}

.documentation pre {
  background-color: #3d3d3d;
  padding: 3px;
  border-radius: 4px;
  overflow-x: auto;
  font-family: Consolas, 'Courier New', monospace;
}

.documentation code {
  background-color: #3d3d3d;
  padding: 2px 4px;
}

.documentation table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.documentation table th,
.documentation table td {
  border: 1px solid #3d3d3d;
  padding: 5px;
  text-align: left;
}

.documentation table th {
  background-color: #3d3d3d;
  font-weight: bold;
}

/* Important equations in shaded box */


.equation-box {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin: 5px auto;         /* Center the box */
  padding: 5px 10px;
  font-family: Arial, sans-serif;
  width: fit-content;       /* Box width will only fit its content */
  max-width: 100%;          /* Prevent overflow if the content is too wide */
}


/* Responsive Design */
@media (max-width: 768px) {
  .search-engine-reference {
      flex-direction: column;
  }

  .sidebar {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #ddd;
  }

  .documentation {
      padding: 15px;
  }

  .collapse-toggle {
      left: 10px;
      top: 10px;
  }
}

.header-container-search-engine {
  padding: 0px;
  width: 100%;
  background-color: #0e0e0e;
  display: flex;
  justify-content: left;
  z-index: 1000;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); 
}

.header-title-search-engine {
  font-size: 23px;
  font-weight: 400;
  color: #ffffff;
}