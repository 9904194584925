.header-container {
  width: 100%;
  height: 65px;
  background-color: #262626;
  display: flex;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

/* 
  Cuando la resolución sea < 800px
  y .header-container tenga la clase .messages-expanded 
  (que implica isMessagesCollapsed === false),
  se ocultan el logo y el botón de país.
*/
@media (max-width: 800px) {
  .header-container.messages-expanded .header-logo,
  .header-container.messages-expanded .country-button {
    display: none;
  }
}

.homepage-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  padding: 5px 0px 0px 0px;
  box-sizing: border-box;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-title {
  font-size: 23px;
  font-weight: 400;
  color: #ffffff;
}

.header-logo {
  padding: 0px;
  width: 31px;
  height: auto;
  margin: 6px;
  color: #b8b8b8;
}

.header-chatbar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
}

@media (max-width: 800px) {
  .header-title {
    display: none; /* Oculta el título cuando la pantalla es pequeña */
  }
}

@media (max-width: 531px) {
  .header-container {
    height: 83px;
  }
}

@media (max-width: 337px) {
  .header-container {
    height: 100px;
  }
}

.country-selector {
  display: flex;
  align-items: center;
  height: 63px; 
  position: relative;
}

.country-button {
  margin-right: 10px;
  background-color: #262626;
  border: none;
  border-radius: 5px;
  color: #f6f6f6;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
}

.country-button:hover {
  background-color: #0e0e0e;
}

.country-dropdown {
  position: absolute;
  top: 70%;
  left: 0;
  background-color: #262626;
  border-radius: 5px;
  margin-top: 0px;
  padding: 5px;
  min-width: 0px;
  z-index: 1001;
}

.country-dropdown button {
  background: none;
  border: none;
  color: #f6f6f6;
  display: block;
  width: 100%;
  text-align: left;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
}

.country-dropdown button:hover {
  background-color: #0e0e0e;
  border-radius: 5px;
}
