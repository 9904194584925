/* General styles for the chat bar */
.chat-bar {
  padding: 0px 10px;
  border-radius: 5px;
  position: static;
  top: 100%;
  left: 0;
  max-height: 700px;
  width: 100%;
  max-width: 964px;
  background-color: #262626;
  z-index: 999px;
  max-height: 80h;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center; /* Añadido para centrar horizontalmente */
}



/* Estilos para el contenedor de mensajes del chat */
.chat-messages {
  border-radius: 5px;
  margin-bottom: 10px !important; /* Espacio adicional en la parte inferior */
  background-color: #0e0e0e;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 960px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0px auto; /* Centra el contenedor */
}



.chat-message {
  padding: 7px;
  border-radius: 5px;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 1.2; /* Line spacing adjustment */
}

/* Additional style for user messages */
.chat-message.user-message {
  align-self: flex-end;
  font-size: 15px;
  background-color: #4f4f4f;
  color: #f6f6f6;
  max-width: 90%; /* Max width for user messages */
}

/* Additional style for model messages */
.chat-message.model-message,
.chat-message.model-calculator-message {
  align-self: flex-start;
  font-size: 15px;
  background-color: #0e0e0e;
  color: #f6f6f6;
}

.progress-update-message {
  padding: 0px;
  background-color: #0e0e0e;
  color: #fff;
  padding: 0px;
  border-radius: 4px;
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 4px;
}

.progress-anim-container {
  position: relative;
  width: 10px;
  height: 10px;
  overflow: hidden;
}

/* Estilo base para cada símbolo */
.progress-anim-container .symbol {
  position: absolute; /* Para superponer los símbolos */
  width: 100%; /* Asegúrate de que ocupe todo el ancho del contenedor */
  height: 100%; /* Asegúrate de que ocupe toda la altura del contenedor */
  text-align: center; /* Centra el texto horizontalmente */
  line-height: 7px; /* Ajusta la altura de línea al tamaño del contenedor */
  font-size: 1.4em; /* Tamaño relativo al contenedor */
  opacity: 0; /* Oculta los símbolos por defecto */
  animation: fadeSymbols 2s infinite;
}

/* Delay para cada símbolo */
.progress-anim-container .symbol:nth-child(1) {
  animation-delay: 0s;
}
.progress-anim-container .symbol:nth-child(2) {
  animation-delay: 0.5s;
}
.progress-anim-container .symbol:nth-child(3) {
  animation-delay: 1s;
}
.progress-anim-container .symbol:nth-child(4) {
  animation-delay: 1.5s;
}

/* Animación: cada símbolo aparece y desaparece suavemente */
@keyframes fadeSymbols {
  0%   { opacity: 0; }
  10%  { opacity: 1; }
  25%  { opacity: 1; } 
  35%  { opacity: 0; }
  100% { opacity: 0; }
}

/* Styles for progress update messages */
.progress-update-message {
  font-size: 13px;
}

/* Styles for system notification messages */
.system-notification-message {
  background-color: #eaf5ff; /* Light blue background */
  color: #0b61a4; /* Dark blue text */
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
}

/* Styles for the calculator message container */
.calculator-container {
  background-color: #262626;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 2px;
  position: relative;
}

.calculator-field {
  margin-bottom: 2px;
}

.calculator-field h4 {
  margin: 0 0 0px 0;
  font-size: 1rem;
  color: #f6f6f6;
}

.calculator-field ul {
  margin-top: 10px;
  padding-left: 8px;
}

.calculator-field ul li {
  margin: 0px 0;
}

.calculator-output {
  font-size: 1.1rem;
  color: #94c392;
}

.collapse-button {
  background-color: #262626;
  color: #d1d1d1;
  border: none;
  border-radius: 3px;
  padding: 5px 3px;
  cursor: pointer;
}

.calculator-header {
  display: flex;
  justify-content: space-between; /* Llévalo a los extremos */
  align-items: center;            /* Alinea verticalmente */
  gap: 8px;                       /* Espacio horizontal */
  margin-bottom: 4px;
}

.collapse-button:hover {
  background-color: #0e0e0e;
}


/* Container for the code block */
.syntaxhighlighter {
  background-color: black !important;
  padding: 20px 10px 10px 10px;
  border-radius: 5px !important;
  color: #f6f6f6 !important;
  font-family: 'Courier New', Courier, monospace !important;
  overflow-x: auto; /* Allow horizontal scroll for long lines */
  position: relative;
}

/* Adjust the pre tag inside SyntaxHighlighter */
.syntaxhighlighter pre {
  white-space: pre-wrap !important;
  word-break: break-word !important;
  margin: 0; /* Remove default margin */
}

/* Styles for the chat input container */
/* Asegúrate de que el contenedor pueda crecer con el contenido */
.chat-input-container {
  /* Elimina la altura fija si aún no lo has hecho */
  /* height: 40px; */
  position: relative;
  padding: 1px;
  background-color: #0e0e0e;
  border-radius: 12px;
  display: flex;
  align-items: flex-end; /* Alinea los botones al final */
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  justify-content: space-between;
}

:root {
  --chat-input-initial-height: 36px; /* Define la altura inicial por defecto */
}

.chat-input {
  width: 100%;
  background-color: #0e0e0e;
  font-size: 16px;
  color: #f6f6f6;
  padding: 5px;
  border: none;
  margin: 0 10px;
  resize: none; /* Evita el redimensionamiento manual */
  height: auto; /* Permite que la altura se ajuste automáticamente */
  min-height: var(--chat-input-initial-height); /* Usa la variable para la altura inicial */
  max-height: 130px; /* Altura máxima de 300px */
  overflow-y: auto; /* Muestra scrollbar cuando excede la altura máxima */
  box-sizing: border-box;
  outline: none; /* Elimina el contorno al enfocar */
  line-height: 1.5;
}


/* Alinea los botones al final del contenedor */
.send-button,
.stop-button,
.extra-button {
  align-self: center;
}

/* Styles for the send button */
.send-button {
  margin-right: 2px;
  border-radius: 10px;
  padding: 0;
  background-color: #d1d1d1;
  color: #3d3d3d;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease; /* Smooth color transition */
}

.send-button:hover:not(.disabled) {
  background-color: #b0b0b0; /* Change color on hover */
}

/* Styles for the disabled send button */
.send-button.disabled {
  background-color: #5d5d5d;
  color: #262626;
  cursor: not-allowed;
}

/* Icon inside the send button */
.send-button i {
  font-size: 22px;
  height: 32px;
  width: 32px;
}

/* Styles for the stop button */
.stop-button {
  margin-right: 5px; /* Space between stop and send buttons */
  border-radius: 10px;
  padding: 0;
  background-color: #0e0e0e;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  margin-left: 10px; /* Space between chat input and stop button */
}

.stop-button:hover:not(.disabled) {
  background-color: #3d3d3d;
}

.stop-button i {
  font-size: 22px;
  height: 32px;
  width: 32px;
}

/* Styles for the spinner inside the send button */
.send-button-spinner {
  background-color: #0e0e0e;
  color: #d1d1d1;
  border-width: 2px;
  padding: 0;
  margin-right: 7px;
}

.send-button .send-button-spinner {
  margin: 0 auto;
  width: 22px;
  height: 22px;
}

.hidden {
  display: none; /* Completely hide the element */
}

/* Styles for lists within chat messages */
.chat-message ol {
  margin: 0;
  padding-left: 5px; /* Adjust list indentation */
}

.chat-message li {
  display: flex; /* Cambia el layout a flexbox */
  flex-direction: row; /* Alinea los elementos horizontalmente */
  align-items: center; /* Alinea verticalmente los elementos en el centro */
  gap: 2px; /* Espacio entre los elementos */
  line-height: 1.5; /* Ajusta el espaciado entre líneas si es necesario */
}


.chat-message li p {
  margin: 0;
  padding: 0;
}

/* Styles for paragraphs within chat messages */
.chat-message p {
  margin-top: 0;
  margin-bottom: 0;
}

/* Disclaimer at the bottom */
.ai-disclaimer {
  margin-top: 2px;
  font-size: 12px;
  color: #b0b0b0;
  text-align: center;
  max-width: 720px;
  width: 100%;
  box-sizing: border-box;
}

.chat-messages.empty {
  height: 0; /* Colapsa la altura cuando está vacío */
  padding: 0; /* Elimina el padding para evitar espacios extra */
  overflow: hidden; /* Asegúrate de que no se muestre contenido desplazado */
}

.send-button.sending {
  background-color: transparent; /* Elimina el color de fondo */
  color: inherit; /* Mantiene el color del texto/icono */
  cursor: default; /* Opcional: Cambia el cursor si lo deseas */
}

.extra-button {
  margin-right: 5px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  gap: 8px; /* Espacio entre los íconos */
  padding: 8px;
  background-color: #0e0e0e;
  border: none;
  border-radius: 8px;
  color: #f6f6f6;
  cursor: pointer;
}

/* Hover para el botón adicional */
.extra-button:hover {
  background-color: #3d3d3d;
}

.chat-messages.collapsed {
  display: none;
}

.chat-message.system-progress-message {
  padding: 0px; /* Ajusta el valor que desees */
}

.documentation-button {
  background-color: #262626;
  color: #d1d1d1;
  border: none;
  border-radius: 3px;
  padding: 5px 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.documentation-button:hover {
  background-color: #0e0e0e;
}

.button-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Separador entre botones */
.separator {
  font-size: 16px;
  color: #d1d1d1;
  margin: 0px;
  user-select: none; /* Evita que el usuario seleccione el separador */
}